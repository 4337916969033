import React, { FC, useState } from 'react';
import { DEFAULT_RIM_PROFILE, DEFAULT_TYPE_ID_POLIKARBONAT, DEFAULT_TYPE_ID_SHIFER, DEFAULT_TYPE_ID_SHTAKETNIK } from '../../../constants/user';
import './productdescription.scss';

interface IProps {
  type: string;
  productID?: string;
}

const ProductDescriptionInner: FC<IProps> = ({type, productID}) => {
  const [sortData, setSortData] = useState('Описание');
  // const ulData = ['Описание', 'Доставка', 'Применение', 'Оплата'];
  const ulData = ['Описание', 'Доставка', 'Оплата'];
  const switchProductDescription = () => {
    
    if (type === DEFAULT_TYPE_ID_POLIKARBONAT) {
      return (<div className="productinfo__description_description">
        <p>
        Сотовый поликарбонат – самый оптимальный вариант для теплицы или парника с отличной светопропускной способностью.
        </p>
        <p>
        Лист сотового поликарбоната имеет низкий вес, что в 19 раз легче стекла аналогичной толщины, сохраняет упругость и эластичность при больших изгибах. 
        </p>
        <p>
        UV-слой покрывает лицевую сторону листа сотового поликарбоната и препятствует разрушению под воздействием ультрафиолета.
        </p>
        <p>
        Также поликарбонат считается экологически чистым материалом. Не содержит вредных веществ, не имеет запаха. Не подвержен грибкам и плесени, хорошо моется. Антибактериальный.
        </p>
        <p>
        С обеих сторон лист сотового поликарбоната покрыт защитной пленкой. С лицевой стороны, которая при монтаже должна быть ориентирована к солнцу, расположен логотип. После монтажа листов поликарбоната защитная пленка должна быть удалена.
        </p>
      </div>)
    }
    if (type === DEFAULT_TYPE_ID_SHTAKETNIK) {
      return (<div className="productinfo__description_description">
        <p>
        Забор из металлического штакетника представляет собой вертикально установленные планки с небольшими зазорами между ними. Для производства штакетника используют оцинкованный металл, который обладает высокими эксплуатационными характеристиками.
        </p>
        <p>
        Забор из штакетника не загораживает вид, поэтому хорошо подходят для установки с лицевой стороны участка. Металлический штакетник представлен в широкой цветовой палитре, благодаря чему вы найдете идеальный оттенок под ваш дизайн.
        </p>
        <h3>
        Забор из металлического штакетника обладает рядом преимуществ:
        </h3>
        <ul>
          <li>
            <b>Долговечность.</b> Забор из штакетника гарантированно прослужит несколько десятилетий. Он не изнашивается от воздействия погодных явлений, поэтому не нуждается в постоянной очистке и покраске. Яркое полимерное покрытие долго сохраняет свои свойства;
          </li>
          <li>
            <b>Возможность ремонта.</b> В случае повреждения одной планки евроштакетника вам не придется менять всю секцию сразу. Особенность конструкции позволяет заменить каждый элемент по отдельности;
          </li>
          <li>
            <b>Легкий монтаж.</b> Сплошной профнастил может создать на участке безвоздушную зону. Это плохо скажется на растениях. Металлический штакетник обеспечивает постоянное поступление воздуха благодаря зазорам между планками;
          </li>
          <li>
            <b>Циркуляция воздуха.</b>  Сплошной профнастил может создать на участке безвоздушную зону. Это плохо скажется на растениях. Металлический штакетник обеспечивает постоянное поступление воздуха благодаря зазорам между планками;
          </li>
          <li>
            <b>Отсутствие парусности.</b> Глухие заборы подвергаются сильной нагрузке при агрессивных порывах ветра. Из-за этого они могут накрениться. Металлический штакетник пропускает воздух, поэтому устанавливать его можно даже на очень ветреных территориях;
          </li>
          <li>
            <b>Эстетичный внешний вид.</b> Забор выглядит стильно и опрятно. Дизайнеры предлагают различные варианты оформления, которые будут соответствовать общей стилистике участка.
          </li>
        </ul>
      </div>)
    }
    if (type === DEFAULT_TYPE_ID_SHIFER) {
      return (<div className="productinfo__description_description">
        {productID === DEFAULT_RIM_PROFILE ? 
          <>
            <p><strong>Лист "Римская волна" (шифер) толщиной 5.8мм хризотилцементный.</strong></p>
            <p><strong>Размер листа 1,75*0,98 метра,</strong> прекрасно подходит для кровли,заборов, ограждений.</p>
            <p>Цена за лист площадью 1,715м2.</p>
            <p>Посмотреть вы можете у нас <strong>на складе</strong>  с 8.00 до 19.00 по адресу: ул. П.Глебки 11, Минск ( район метро Спортивная, Каменная горка).</p>
          </>
        
        : null}
        <p>
        <strong>Хризотилцементные волнистые листы</strong>  – это традиционный строительный материал с более чем вековой историей применения, за которым в народе прочно закрепилось название «шифер». Хризотилцемент является композиционным материалом. В его состав входят всего три компонента: вода, цемент и природный волокнистый минерал хризотил, армирующий цементную матрицу. Достоинствами хризотилцементных изделий неоспоримо являются:
        </p>
        <ul>
          <li>
            <strong>пожаробезопасность</strong> – хризотилцементные листы не горят и при контакте с огнем не выделяют вредных веществ;
          </li>
          <li>
            <strong>морозостойкость</strong>, водостойкость и низкая теплопроводность — материал надежно сохраняет в здании тепло, при этом сам почти не нагревается, подходит для любых климатических зон;
          </li>
          <li>
            <strong>звукоизоляция</strong> – этот кровельный материал отлично «гасит» шум осадков и других внешних факторов;
          </li>
          <li>
            долговечность, надежность и прочность;
          </li>
          <li>
            экологичность и безопасность применения;
          </li>
          <li>
            экономичность и простота монтажа.
          </li>
        </ul>
      </div>)
    }
    return null;
  }
  return (
    <div className="productinfo__description">
      <ul className="productinfo__description_nav">
        {ulData.map(item => (
          <li 
            key={item}
            className={`productinfo__description__navitem ${sortData === item ? 'active' : null}`}
            onClick={() => setSortData(item)}
            >
            {item}
          </li>
        ))}
      </ul>
      <div className="productinfo__description_line"></div>
      {sortData === ulData[0] &&
        switchProductDescription()
        // (type === DEFAULT_TYPE_ID_POLIKARBONAT &&
        //   <div className="productinfo__description_description">
        //     <p>
        //     Сотовый поликарбонат – самый оптимальный вариант для теплицы или парника с отличной светопропускной способностью.
        //     </p>
        //     <p>
        //     Лист сотового поликарбоната имеет низкий вес, что в 19 раз легче стекла аналогичной толщины, сохраняет упругость и эластичность при больших изгибах. 
        //     </p>
        //     <p>
        //     UV-слой покрывает лицевую сторону листа сотового поликарбоната и препятствует разрушению под воздействием ультрафиолета.
        //     </p>
        //     <p>
        //     Также поликарбонат считается экологически чистым материалом. Не содержит вредных веществ, не имеет запаха. Не подвержен грибкам и плесени, хорошо моется. Антибактериальный.
        //     </p>
        //     <p>
        //     С обеих сторон лист сотового поликарбоната покрыт защитной пленкой. С лицевой стороны, которая при монтаже должна быть ориентирована к солнцу, расположен логотип. После монтажа листов поликарбоната защитная пленка должна быть удалена.
        //     </p>
        //   </div>
        // )
        }
      {sortData === ulData[1] &&
        <div className="productinfo__description_description">
          <h3>
            Доставка по всем регионам Республики Беларусь
          </h3>
          <p>
          Доставка осуществляется на платной основе по Минску и всей территории Беларуси. Стоимость доставки зависит от количества заказываемого товара и пункта доставки. Точную стоимость доставки можно уточнить у менеджеров компании.
          </p>
          <h3>
            Самовывоз
          </h3>
          <p>
            По предварительной договорённости, забрать товар можно по адресу: г.Минск, ул.П.Глебки, д.11
          </p>
        </div>
      }
      {sortData === ulData[2] && 
        <div className="productinfo__description_description">
          <p>
            Мы позаботились о том, чтобы оплата покупки была доступна в той форме (наличной или безналичной), которую предпочитаете лично вы. Независимо от выбранной формы оплаты, расчет производится только в белорусских рублях.
          </p>
          <h3>
            Наличный расчет:
          </h3>
          <p>
            - оплата наличными деньгами при доставке товара курьером
            - оплата товара по кассе при совершении самовывоза
          </p>
          <h3>
            Безналичный расчет
          </h3>
          <p>
            Для юридических лиц есть возможность приобрести у нас любой товар по безналичному расчету.
          </p>
          <p>
            Для этого необходимо зарезервировать товар удобным для Вас способом: <br />
            позвонить в call-центр по телефону, указанному в шапке сайта <br />
            оформить заказ на сайте через корзину <br />
            сбросить заявку на e-mail info@skrama.by с указанием товара и реквизитов юридического лица для выставления счета.
          </p>
        </div>
      
      } 
    </div>
  )
}

export const ProductDescription = React.memo(ProductDescriptionInner);